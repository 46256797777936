import { Grid, Card, CardHeader, CardContent, Typography, Button } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { tt } from 'src/locales/i18n';
import { dispatch, useSelector } from '../../redux/store';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { onNextStep, onPrevStep } from 'src/redux/slices/onboarding';
import SpacingBox from 'src/components/spacingbox/SpacingBox';
import { SeoIllustration } from 'src/assets';
import useAuth from '../../hooks/useAuth';
import { EditoricalAPI } from '../../utils/api';
import ColorSinglePicker from 'src/components/color-utils/ColorSinglePicker';
import { actions as userActions } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

const STEPNO = 2;

const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

type OnboardingStep2Props = {
  visible: boolean;
};

interface FormValuesProps {
  publication: string;
  publicationtype: string;
  color: string;
  autopublish: boolean;
}

export default function OnboardingStepType({ visible }: OnboardingStep2Props) {
  // const { themeStretch } = useSettings();
  const { activeStep } = useSelector((state) => state.onboarding);
  const currentStep = activeStep === STEPNO;
  const { user } = useAuth();

  const defaultValues = useMemo(
    () => ({
      publication: '',
      publicationtype: 'YOUTUBE',
      color: '#00AB55',
      publication_unused: '',
      autopublish: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const OnboardingSchema = Yup.object().shape({
    publication:
      user?.tenants?.length > 0 && user?.tenants[0].publications?.length > 0
        ? Yup.string().optional()
        : Yup.string().required(tt('pages.onboarding.step-type.publication-required')),
    publicationtype: Yup.string().required(
      tt('pages.onboarding.step-type.publicationtype-required')
    ),
    publication_unused: Yup.string().optional(),
    color: Yup.string().optional(),
    autopublish: Yup.string().optional(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(OnboardingSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    control,
    // setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (user && user.tenants.length > 0) {
        if (!user.tenants[0].publications || user.tenants[0].publications.length === 0) {
          const tenantId = user.tenants[0].id;
          const result = await EditoricalAPI.createPublication(tenantId, {
            name: data.publication,
            type: data.publicationtype,
            color: data.color,
            autoPublish: data.autopublish,
          });
          dispatch(
            userActions.addPublication({
              index: 0,
              publication: {
                id: result.id,
                type: data.publicationtype,
                setting: {
                  name: data.publication,
                  autoPublish: false,
                  color: '#ffffff',
                },
              },
            })
          );
        }
        dispatch(onNextStep());
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // TODO load data here and fill it in
    // console.log('currentStep 0 -', currentStep);
  }, [currentStep]);

  const handlePrevStep = () => {
    dispatch(onPrevStep());
  };

  const disabled =
    user &&
    user.tenants.length > 0 &&
    user.tenants[0].publications &&
    user.tenants[0].publications.length > 0;

  return (
    <>
      {visible && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            <Grid item xs={12} md={2} sx={{ paddingLeft: '0 !important' }}>
              <SeoIllustration
                sx={{
                  p: 3,
                  width: '100%',
                  margin: { xs: 'auto', md: 'inherit' },
                  display: { xs: 'none', md: 'block' },
                  padding: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SpacingBox>
                <Card>
                  <CardHeader title={tt('pages.onboarding.step-type.publication-title')} />
                  <CardContent>
                    <SpacingBox>
                      <Typography sx={{ typography: 'paragraph' }}>
                        {tt('pages.onboarding.step-type.publication-text')}
                      </Typography>
                      {disabled ? (
                        <RHFTextField
                          name="publication_unused"
                          label={tt('pages.onboarding.step-type.publication')}
                          disabled={true}
                          value={user.tenants[0].publications[0].setting.name}
                        />
                      ) : (
                        <RHFTextField
                          name="publication"
                          label={tt('pages.onboarding.step-type.publication')}
                        />
                      )}
                      <Controller
                        name="color"
                        control={control}
                        render={({ field }) => (
                          <ColorSinglePicker
                            disabled={disabled}
                            value={field.value}
                            onChange={field.onChange}
                            colors={COLOR_OPTIONS}
                          />
                        )}
                      />
                      <RHFSwitch
                        name="autopublish"
                        label={tt('pages.onboarding.step-type.publicationautopublish')}
                        sx={{ m: 0 }}
                      />
                      <Typography sx={{ typography: 'paragraph' }}>
                        {tt('pages.onboarding.step-type.publicationtype-text')}
                      </Typography>
                      <RHFTextField
                        name="publicationtype"
                        label={tt('pages.onboarding.step-type.publicationtype')}
                        disabled={true}
                      />
                    </SpacingBox>
                  </CardContent>
                </Card>
              </SpacingBox>
            </Grid>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            <Grid container spacing={3} sx={{ marginTop: 0, marginLeft: 0 }}>
              <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
              <Grid item xs={6} md={4}>
                <Button size="large" type="button" variant="contained" onClick={handlePrevStep}>
                  {tt('actions.prev')}
                </Button>
              </Grid>
              <Grid container item xs={6} md={4} justifyContent="flex-end">
                <Button size="large" type="submit" variant="contained">
                  {tt('actions.next')}
                </Button>
              </Grid>
              <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
