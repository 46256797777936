import { createContext, ReactNode, useEffect } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Auth0ContextType } from '../@types/auth';
import { AUTH0_API } from '../config';
import { dispatch, useSelector } from 'src/redux/store';
import { actions as userActions } from 'src/redux/slices/user';
import { UserBackend } from 'src/@types/user';
import { actions as authActions } from 'src/redux/slices/auth';
import { PATH_AUTH } from 'src/routes/paths';
import { EditoricalAPI } from 'src/utils/api';

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<Auth0ContextType | null>(null);

let auth0Client: Auth0Client | null = null;

function AuthProvider({ children }: AuthProviderProps) {
  const userAuth = useSelector((state) => state.auth);
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    const initialize = async () => {
      try {
        const currentDateTime = new Date();
        const sessionDateTime = new Date(userAuth.sessionEnd || 0);

        auth0Client = new Auth0Client({
          client_id: AUTH0_API.clientId || '',
          domain: AUTH0_API.domain || '',
          redirect_uri: window.location.origin,
        });

        if (currentDateTime > sessionDateTime) {
          await auth0Client.checkSession();
          const authenticated = await auth0Client.isAuthenticated();

          dispatch(
            authActions.init({
              isAuthenticated: authenticated,
              isInitialized: true,
            })
          );

          setUserData(authenticated);
        } else {
          setUserData(true);
        }
      } catch (err) {
        console.error(err);
        dispatch(authActions.logout({ isAuthenticated: false }));
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async () => {
    await auth0Client?.loginWithPopup();
    const authenticated = (await auth0Client?.isAuthenticated()) ?? false;

    setUserData(authenticated);
  };

  const setUserData = async (authenticated: boolean) => {
    if (authenticated) {
      const user = await auth0Client?.getUser();
      if (!user || !user.sub) {
        return;
      }

      if (!user?.email_verified) {
        dispatch(authActions.logout({ isAuthenticated: false }));
        // FIXME fix this to be in a router component and with navigate?
        window.location.href = PATH_AUTH.verify;
        return;
      }

      const backendUser = await backendLoginOrCreate(user.sub, user.email, user.nickname);
      if (!backendUser) {
        console.log('no backenduser');
        return;
      }

      const token = await auth0Client?.getIdTokenClaims();
      const auth0token = token?.__raw;

      if (!token || !auth0token) {
        // TODO error handling
        return;
      }

      const tokenExpires = token.exp || 0;
      const sessionEnd = new Date(tokenExpires * 1000);
      dispatch(
        authActions.login({
          isAuthenticated: authenticated,
          sessionEnd: sessionEnd,
          isVerified: true,
        })
      );

      dispatch(
        userActions.setUser({
          id: backendUser.id,
          tenants: backendUser.tenants,
          displayName: backendUser.name,
          auth0Id: backendUser.auth0Id,
          token: auth0token,
          photoURL: user.picture,
          email: user.email,
          role: 'creator',
        })
      );
    }
  };
  const logout = async () => {
    dispatch(
      userActions.setUser({
        id: null,
        tenants: null,
        auth0Id: null,
        token: null,
      })
    );

    await auth0Client?.logout();

    dispatch(authActions.logout({ isAuthenticated: false }));
  };

  const backendLoginOrCreate = async (
    auth0Id: string,
    email?: string,
    nickname?: string
  ): Promise<UserBackend | null> => {
    try {
      const auth0token = (await auth0Client?.getIdTokenClaims())?.__raw;
      if (!auth0token) {
        return null;
      }

      const user = await auth0Client?.getUser();

      if (!user || !user.sub) {
        return null;
      }

      // FIXME move to global axios config
      EditoricalAPI.initialize(auth0token, user.sub);

      const response = await EditoricalAPI.loginOrCreate({
        auth0Id,
        nickname: nickname ? nickname : '',
        email: email ? email : '',
      });

      return {
        id: response.id,
        name: response.name,
        auth0Id: response.auth0Id,
        tenants: response.tenants,
      };
    } catch (err) {
      // TODO
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        method: 'auth0',
        isAuthenticated: userAuth.isAuthenticated,
        isInitialized: userAuth.isInitialized,
        isVerified: userAuth.isVerified,
        user: userState,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
