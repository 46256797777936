import { useState, useEffect } from 'react';
// @mui
import { Card, Container, DialogTitle, Grid } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { openModal, closeModal } from '../../redux/slices/calendar';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// @types
// components
import Page from '../../components/Page';
import { DialogAnimate } from '../../components/animate';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections

import RoundButton from '../../components/buttons/RoundButton';
import EditoricalList from 'src/sections/@dashboard/editorial/EditoricalList';
import { getPosts } from 'src/redux/slices/posts';
import EditoricalForm from '../../sections/@dashboard/editorial/EditoricalForm';
import Calendar from 'src/organisms/Calendar';
import { t } from 'i18next';
// ----------------------------------------------------------------------

export type PostTable = {
  id: string;
  title: string;
};

export default function EditoricalTasks() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<PostTable[]>([]);
  const { isOpenModal, selectedRange } = useSelector((state) => state.calendar);
  const posts = useSelector((state) => state.posts);

  // ------------------------------------------------------------------------
  // Initialization
  // ------------------------------------------------------------------------

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);

  useEffect(() => {
    if (!posts) return;
    const data = posts.map((post) => ({
      id: post.id,
      title: post.title,
    }));

    setTableData(data);
  }, [posts]);

  const handleAddEvent = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Page title={t('pages.dashboard.title')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={t('pages.dashboard.title')}
          links={[
            { name: t('pages.dashboard.title'), href: PATH_DASHBOARD.root },
            { name: t('pages.dashboard.breadcrumb') },
          ]}
          action={
            <RoundButton
              icon={'eva:plus-fill'}
              onClick={handleAddEvent}
              title={t('pages.dashboard.addpost')}
            />
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card>
              <Calendar />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <EditoricalList tableData={tableData} tableLabels={[]} />
          </Grid>
        </Grid>

        <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
          <DialogTitle>
            <>{t('pages.dashboard.dialog-add.title')}</>
          </DialogTitle>

          {/* TODO add modal add dialog which fits editorical */}
          <EditoricalForm event={{}} range={selectedRange} onCancel={handleCloseModal} />
        </DialogAnimate>
      </Container>
    </Page>
  );
}
