import FullCalendar, { DateSelectArg, EventClickArg, EventDropArg } from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
import { useState, useRef, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getEvents, updateEvent, selectEvent, selectRange } from '../../redux/slices/calendar';
// hooks
import useResponsive from '../../hooks/useResponsive';
// @types
import { CalendarView } from '../../@types/calendar';

// sections
import { CalendarStyle, CalendarToolbar } from '../../sections/@dashboard/calendar';
import i18n from 'src/locales/i18n';

// ----------------------------------------------------------------------

export type PostTable = {
  id: string;
  title: string;
};
// const selectedEventSelector = (state: RootState) => {
//   const { events, selectedEventId } = state.calendar;
//   if (selectedEventId) {
//     return events.find((_event) => _event.id === selectedEventId);
//   }
//   return null;
// };

export default function Calendar() {
  // const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'sm');

  const calendarRef = useRef<FullCalendar>(null);

  const [date, setDate] = useState(new Date());
  // const [tableData, setTableData] = useState<PostTable[]>([]);

  const [view, setView] = useState<CalendarView>(isDesktop ? 'dayGridMonth' : 'listWeek');

  // const selectedEvent = useSelector(selectedEventSelector);

  const { events /*, isOpenModal, selectedRange*/ } = useSelector((state) => state.calendar);

  // ------------------------------------------------------------------------
  // Initialization
  // ------------------------------------------------------------------------

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isDesktop ? 'dayGridMonth' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);

  // ------------------------------------------------------------------------
  // Calendar events
  // ------------------------------------------------------------------------

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView: CalendarView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg: DateSelectArg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(selectRange(arg.start, arg.end));
  };

  const handleSelectEvent = (arg: EventClickArg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleResizeEvent = async ({ event }: EventResizeDoneArg) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = async ({ event }: EventDropArg) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  // const handleAddEvent = () => {
  //   dispatch(openModal());
  // };
  //
  // const handleCloseModal = () => {
  //   dispatch(closeModal());
  // };

  return (
    <CalendarStyle>
      <CalendarToolbar
        date={date}
        view={view}
        onNextDate={handleClickDateNext}
        onPrevDate={handleClickDatePrev}
        onToday={handleClickToday}
        onChangeView={handleChangeView}
      />
      <FullCalendar
        weekends
        editable
        droppable
        selectable
        events={events}
        ref={calendarRef}
        rerenderDelay={10}
        initialDate={date}
        initialView={view}
        dayMaxEventRows={3}
        eventDisplay="block"
        headerToolbar={false}
        allDayMaintainDuration
        eventResizableFromStart
        select={handleSelectRange}
        eventDrop={handleDropEvent}
        eventClick={handleSelectEvent}
        eventResize={handleResizeEvent}
        height={isDesktop ? 720 : 'auto'}
        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
        locale={i18n.language}
      />
    </CalendarStyle>
  );
}
