import * as Yup from 'yup';
import merge from 'lodash/merge';
import { useSnackbar } from 'notistack';
import { EventInput } from '@fullcalendar/common';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { useDispatch } from '../../../redux/store';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { addPost } from '../../../redux/slices/posts';
import { t } from 'i18next';
import { tt } from 'src/locales/i18n';

// ----------------------------------------------------------------------

const getInitialValues = (event: EventInput, range: { start: Date; end: Date } | null) => {
  const _event = {
    title: '',
    description: '',
    textColor: '#1890FF',
    allDay: false,
    noDate: false,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date(),
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  title: string;
  description: string;
  textColor: string;
  allDay: boolean;
  noDate: boolean;
  start: Date | null;
  end: Date | null;
};

type Props = {
  event: EventInput;
  range: {
    start: Date;
    end: Date;
  } | null;
  onCancel: VoidFunction;
};

export default function EditoricalForm({ event, range, onCancel }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: FormValuesProps) => {
    try {
      dispatch(addPost({ title: data.title }));
      enqueueSnackbar('Create success!');

      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label={tt('pages.dashboard.dialog-add.edit-label')} />
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          <>{t('actions.cancel')}</>
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          <>{t('pages.dashboard.dialog-add.button-add')}</>
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
