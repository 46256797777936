import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AFTER_LOGIN_ONBOARDING } from 'src/config';
import { checkOnboarding } from 'src/utils/checkonboarding';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthenticated, isInitialized } = useAuth();
  // TODO add this to a helper library...
  const isOnboarding = checkOnboarding(user);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    if (isOnboarding && pathname !== PATH_AFTER_LOGIN_ONBOARDING) {
      navigate(PATH_AFTER_LOGIN_ONBOARDING, { replace: true });
    }
  }, [isOnboarding, navigate, pathname]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (isOnboarding && pathname !== PATH_AFTER_LOGIN_ONBOARDING) {
    return <></>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
