// @mui
import { alpha, styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
// utils
import cssStyles from 'src/utils/cssStyles';
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.64 }),
  right: '15px',
  bottom: '15px',
  position: 'fixed',
  padding: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 2,
  borderRadius: '24px 24px 24px 24px',
  boxShadow: `-6px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
    0.36
  )}`,
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

// ----------------------------------------------------------------------

type Props = {
  title: string;
  icon: string;
  onClick: VoidFunction;
};

export default function RoundButton({ title, icon, onClick }: Props) {
  return (
    <RootStyle>
      <Tooltip title={title} placement="left">
        <IconButtonAnimate
          color="inherit"
          onClick={onClick}
          sx={{
            p: 1.25,
            transition: (theme) => theme.transitions.create('all'),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.dark, 0.9),
            },
          }}
        >
          <Iconify icon={icon} width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>
    </RootStyle>
  );
}
