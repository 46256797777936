import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import tasksReducer from './slices/tasks';
import userReducer from './slices/user';
import authReducer from './slices/auth';
import postsReducer from './slices/posts';
import onboardingReducer from './slices/onboarding';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // no whitelist means everything in the store is saved during reload
  whitelist: ['user', 'auth', 'posts'],
  // whitelist: [], // nothing is saved in the store during reload
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  posts: postsReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  tasks: tasksReducer,
  product: persistReducer(productPersistConfig, productReducer),
  onboarding: onboardingReducer,
});

export { rootPersistConfig, rootReducer };
