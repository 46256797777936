import { createSlice } from '@reduxjs/toolkit';

type PublicationSetting = {
  color: string;
  id: string;
  name: string;
};

type Publication = {
  id: string;
  setting: PublicationSetting;
};

type Tenant = {
  id: string;
  name: string;
  publications: Publication[];
};

type User = {
  id: string | null;
  tenants: Tenant[];
  displayName: string;
  auth0Id: string | null;
  token: string | null;
  photoURL: string;
  email: string;
  role: 'creator' | 'admin';
};

const initialState: User = {
  id: null,
  tenants: [],
  displayName: '',
  auth0Id: null,
  token: null,
  photoURL: '',
  email: '',
  role: 'creator',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateUsername(state, action) {
      return {
        ...state,
        displayName: action.payload,
      };
    },
    addTenant(state, action) {
      return {
        ...state,
        tenants: [...state.tenants, action.payload],
      };
    },
    addPublication(state, action) {
      if (!state.tenants) return;
      if (state.tenants.length === 0) return;
      if (!action.payload.publication) return;
      if (action.payload.index === undefined) return;

      const { index, publication } = action.payload;

      return {
        ...state,
        tenants: [
          ...state.tenants.map((tenant, tenantIndex) => ({
            ...tenant,
            publications:
              tenantIndex === index
                ? [...tenant.publications, { ...publication }]
                : [...tenant.publications],
          })),
        ],
      };
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
