import { createSlice } from '@reduxjs/toolkit';

type Auth = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isVerified: boolean;
  sessionEnd: Date | null;
};

const initialState: Auth = {
  isAuthenticated: false,
  isInitialized: false,
  isVerified: false,
  sessionEnd: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    init(state, action) {
      const { isAuthenticated, isInitialized } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized,
      };
    },
    login(state, action) {
      const { sessionEnd, isVerified } = action.payload;
      return { ...state, isAuthenticated: true, sessionEnd, isVerified };
    },
    logout(state, action) {
      return {
        ...state,
        isAuthenticated: false,
      };
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
