import i18n, { t } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// config
import { defaultLang } from '../config';

import translationGer from './ger.json';
import translationEnd from './eng.json';

// ----------------------------------------------------------------------

i18n // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    // debug: process.env.NODE_ENV === 'development',
    lng: localStorage.getItem('i18nextLng') || defaultLang.value,
    // lng: 'de',
    fallbackLng: defaultLang.value,
    // fallbackLng: 'de', // use en if detected lng is not available
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      de: { translations: translationGer },
      en: { translations: translationEnd },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

// wrapper for that t returns always a string -> lesser problems with react
export function tt(value: string): string {
  return t(value) as string;
}

export default i18n;
