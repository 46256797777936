import axios from 'src/utils/axios';

type CreatePublicationArgs = {
  name: string;
  color: string;
  autoPublish: boolean;
  type: string;
};

type LoginArgs = {
  auth0Id: string;
  nickname: string;
  email: string;
};

export const EditoricalAPI = {
  initialize: (token: string, userId: string) => {
    axios.defaults.headers.common['x-access-token'] = token;
    axios.defaults.headers.common['x-access-userid'] = userId;
  },

  loginOrCreate: async ({ auth0Id, nickname, email }: LoginArgs) => {
    const response = await axios.post('/login', {
      auth0id: auth0Id,
      name: nickname,
      email,
    });
    return response.data;
  },

  updateUserName: async (userId: string, name: string) => {
    await axios.post(`/user/update-name/${userId}`, {
      name,
    });
  },

  createTenant: async (userId: string, name: string) => {
    const response = await axios.post(`/tenant/${userId}`, {
      name,
    });

    return response.data;
  },

  createPublication: async (
    tenantId: string,
    { name, color, autoPublish, type }: CreatePublicationArgs
  ) => {
    const response = await axios.post(
      `/publication`,
      {
        name,
        color,
        autoPublish,
        type,
      },
      {
        headers: { 'x-tenant-id': tenantId },
      }
    );

    return response.data;
  },
};
