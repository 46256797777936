// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardProps,
  CardHeader,
  TableContainer,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom } from 'src/components/table';

// ----------------------------------------------------------------------

type RowProps = {
  id: string;
  title: string;
};

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  tableData: RowProps[];
  tableLabels: any[];
}

export default function EditoricalList({
  title,
  subheader,
  tableData,
  tableLabels,
  ...other
}: Props) {
  return (
    <Card {...other}>
      {title && title.length > 0 && (
        <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />
      )}

      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.length > 0 &&
                tableData.map((row) => <EditoricalListRow key={row.id} row={row} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {/* <Divider /> */}

      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
        >
          View All
        </Button>
      </Box> */}
    </Card>
  );
}

// ----------------------------------------------------------------------

type AppNewInvoiceRowProps = {
  row: RowProps;
};

function EditoricalListRow({ row }: AppNewInvoiceRowProps) {
  //   const theme = useTheme();

  //   const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  //   const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
  //     setOpenMenuActions(event.currentTarget);
  //   };

  //   const handleCloseMenu = () => {
  //     setOpenMenuActions(null);
  //   };

  //   const handleDownload = () => {
  //     handleCloseMenu();
  //     console.log('DOWNLOAD', row.id);
  //   };

  //   const handlePrint = () => {
  //     handleCloseMenu();
  //     console.log('PRINT', row.id);
  //   };

  //   const handleShare = () => {
  //     handleCloseMenu();
  //     console.log('SHARE', row.id);
  //   };

  //   const handleDelete = () => {
  //     handleCloseMenu();
  //     console.log('DELETE', row.id);
  //   };

  return (
    <TableRow>
      {/* <TableCell>{`${row.id}`}</TableCell> */}

      <TableCell>{row.title}</TableCell>

      {/* <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={handleDownload}>
                <Iconify icon={'eva:download-fill'} />
                Download
              </MenuItem>

              <MenuItem onClick={handlePrint}>
                <Iconify icon={'eva:printer-fill'} />
                Print
              </MenuItem>

              <MenuItem onClick={handleShare}>
                <Iconify icon={'eva:share-fill'} />
                Share
              </MenuItem>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
