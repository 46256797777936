// @mui
import { Container, Grid, Step, StepLabel, Stepper } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { useDispatch, useSelector } from '../../redux/store';
import Page from '../../components/Page';
import { t } from 'i18next';
import { setActiveStep } from 'src/redux/slices/onboarding';
import { useEffect } from 'react';
import { PATH_DASHBOARD } from 'src/routes/paths';
import OnboardingStepUsername from './OnboardingStepUsername';
import OnboardingStepType from './OnboardingStepType';
import OnboardingStepFinish from './OnboardingStepFinish';
import OnboardingStepWelcome from './OnboardingStepWelcome';
import useAuth from 'src/hooks/useAuth';
import { checkOnboarding } from 'src/utils/checkonboarding';

// ----------------------------------------------------------------------

const STEPS: string[] = t('pages.onboarding.steps', { returnObjects: true });

export default function EditoricalOnboarding() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const { activeStep } = useSelector((state) => state.onboarding);
  const isComplete = activeStep === STEPS.length;
  const { user } = useAuth();

  const isOnboarding = checkOnboarding(user);

  // ------------------------------------------------------------------------
  // Initialization
  // ------------------------------------------------------------------------

  useEffect(() => {
    // Check starting page here, which of the items are already filled in.
    if (
      isOnboarding &&
      user?.tenants.length > 0 &&
      (!user?.tenants[0].publications || user?.tenants[0].publications.length === 0)
    ) {
      dispatch(setActiveStep(2));
    }
  }, [dispatch, isOnboarding, user?.tenants]);

  useEffect(() => {
    if (isComplete) {
      window.location.href = PATH_DASHBOARD.editorical.tasks;
    }
  }, [isComplete]);

  return (
    <Page title={t('pages.onboarding.title')}>
      {isComplete ? (
        ''
      ) : (
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            <Grid item xs={12} md={8} sx={{ marginBottom: '15px' }}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        '& .MuiStepLabel-label': {
                          typography: 'subtitle2',
                          color: 'text.disabled',
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
          </Grid>
          <br />
          <OnboardingStepWelcome visible={activeStep === 0} />
          <OnboardingStepUsername visible={activeStep === 1} />
          <OnboardingStepType visible={activeStep === 2} />
          <OnboardingStepFinish visible={activeStep === 3} />
        </Container>
      )}
    </Page>
  );
}
