import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type Tasks = {
  isLoading: boolean;
  error: any;
  tasks: string[];
};

const initialState: Tasks = {
  isLoading: false,
  error: null,
  tasks: [],
};

const slice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getTaskSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },

    addTask(state, action) {
      state.isLoading = true;
      state.tasks.push(action.payload);
    },
    // GET BOARD
    addTaskSuccess(state, action) {
      state.isLoading = false;
      state.tasks = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

export function getTasks({ auth0Id }: { auth0Id: string }) {
  console.log('auth0Id', auth0Id);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/posts', { tenantId: auth0Id });
      dispatch(slice.actions.getTaskSuccess(response.data.board)); // FIXME
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTask({ title, auth0Id }: { title: string; auth0Id: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/posts',
        { auth0Id: auth0Id, title: title },
        { headers: { 'Content-Type': 'application/json' } }
      );

      dispatch(slice.actions.addTaskSuccess(response.data.board)); // FIXME
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
