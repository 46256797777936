import { createSlice } from '@reduxjs/toolkit';
import { EventInput } from '@fullcalendar/common';
// utils
// import axios from '../../utils/axios';
// @types
import { CalendarState } from '../../@types/calendar';
//
import { dispatch } from '../store';
// import { useSelector } from '../store';
// import { getPosts } from 'src/redux/slices/posts';
// import { getStaticWrapperUtilityClass } from '@mui/x-date-pickers/internals/components/PickerStaticWrapper';

// ----------------------------------------------------------------------

const initialState: CalendarState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          if (!event.title) event.title = _event.title;
          if (!event.description) event.description = _event.description;
          if (!event.textColor) event.textColor = _event.textColor;
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId } = action.payload;
      const deleteEvent = state.events.filter((event) => event.id !== eventId);
      state.events = deleteEvent;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    // console.log(posts);

    try {
      // const axoisEvent = {
      //   userId: axios.defaults.headers.common['x-access-userid'],
      // };
      // const response = await axios.post('tasks', axoisEvent);
      // // @ts-ignore
      // response.data.forEach((element) => {
      //   if (!element.dateStart) element.dateStart = new Date().toISOString();
      //   if (!element.dateEnd) element.dateEnd = new Date().toISOString();
      //   if (!element.start) element.start = element.dateStart;
      //   if (!element.end) element.end = element.dateEnd;
      //   if (!element.textColor) element.textColor = '#1890FF';
      //   if (!element.description) element.description = '';
      //   if (!element.allDay) element.allDay = false;
      // });
      dispatch(slice.actions.getEventsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(newEvent: Omit<EventInput, 'id'>) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //   const axoisEvent = {
      //     userid: axios.defaults.headers.common['x-access-userid'],
      //     dateStart: newEvent.start,
      //     dateEnd: newEvent.end,
      //     title: newEvent.title,
      //   };
      //   const response = await axios.post('tasks/create', axoisEvent);
      // const realEvent = {
      //   title: newEvent.title,
      // };
      // dispatch(slice.actions.createEventSuccess(realEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(
  eventId: string,
  updateEvent: Partial<{
    allDay: boolean;
    start: Date | null;
    end: Date | null;
    title: string;
    description: string;
    textColor: string;
  }>
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let updatedEvent = {
        id: eventId,
        allDay: updateEvent.allDay,
        start: updateEvent.start,
        end: updateEvent.end,
        title: updateEvent.title,
        description: updateEvent.description,
        textColor: updateEvent.textColor,
      };
      // console.log(updatedEvent);
      dispatch(slice.actions.updateEventSuccess(updatedEvent));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // console.log(eventId);
    try {
      //   await axios.post('/api/calendar/events/delete', { eventId });
      dispatch(slice.actions.deleteEventSuccess({ eventId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start: Date, end: Date) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
