import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { dispatch } from 'src/redux/store';

type Post = {
  id: string;
  title: string;
};

const initialState: Post[] = [];

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action) {
      const posts = action.payload;
      return [...posts];
    },
    addPosts(state, action) {
      const posts = action.payload;
      return [...state, ...posts];
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

export function getPosts() {
  return async (_dispatch: any, getState: any) => {
    const state = getState();
    const tenantId = state.user.tenants[0].id; // FIXME once a tenant can be selected in frontend make dynamic here
    try {
      const response = await axios.get(`/post/${tenantId}`);
      dispatch(slice.actions.setPosts(response.data));
    } catch (error) {
      // FIXME throw error
    }
  };
}

export function addPost({ title }: { title: string }) {
  return async (_dispatch: any, getState: any) => {
    const state = getState();
    const tenantId = state.user.tenants[0].id; // FIXME once a tenant can be selected in frontend make dynamic here

    const response = await axios.post(
      '/post',
      { tenantId: tenantId, title: title },
      { headers: { 'Content-Type': 'application/json' } }
    );

    dispatch(slice.actions.addPosts([response.data]));
  };
}
