// eslint-disable-next-line import/no-duplicates
import { format, getTime, formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB, de } from 'date-fns/locale';
import { t } from 'i18next';
import i18n from 'src/locales/i18n';

const dateFnsLocale: any = {
  de: de,
  en: enGB,
};

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), t('system.date.formatDate'), {
    locale: dateFnsLocale[i18n.language],
  });
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), t('system.date.formatDateTime'), {
    locale: dateFnsLocale[i18n.language],
  });
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), t('system.date.formatDateTimeSuffix'), {
    locale: dateFnsLocale[i18n.language],
  });
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
