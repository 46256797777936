// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
// Simple Box to create some space between elements
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export default function SpacingBox({ children }: Props) {
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 2,
        rowGap: 3,
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
      }}
    >
      {children}
    </Box>
  );
}
