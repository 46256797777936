import { Grid, Card, CardHeader, CardContent, Typography, Button } from '@mui/material';
import { useEffect } from 'react';
import { tt } from 'src/locales/i18n';
import { dispatch, useSelector } from '../../redux/store';
import { onNextStep } from 'src/redux/slices/onboarding';
import SpacingBox from 'src/components/spacingbox/SpacingBox';
import { SeoIllustration } from 'src/assets';

// ----------------------------------------------------------------------

const STEPNO = 0;

type OnboardingStepWelcomeProps = {
  visible: boolean;
};

export default function OnboardingStepWelcome({ visible }: OnboardingStepWelcomeProps) {
  // const { themeStretch } = useSettings();
  const { activeStep } = useSelector((state) => state.onboarding);
  const currentStep = activeStep === STEPNO;

  useEffect(() => {
    // TODO load data here and fill it in
    // console.log('currentStep 0 -', currentStep);
  }, [currentStep]);

  const handleNextStep = () => {
    dispatch(onNextStep());
  };

  return (
    <>
      {visible && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
          <Grid item xs={12} md={2} sx={{ paddingLeft: '0 !important' }}>
            <SeoIllustration
              sx={{
                p: 3,
                width: '100%',
                margin: { xs: 'auto', md: 'inherit' },
                display: { xs: 'none', md: 'block' },
                padding: 0,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SpacingBox>
              <Card>
                <CardHeader title={tt('pages.onboarding.step-welcome.title')} />
                <CardContent>
                  <SpacingBox>
                    <Typography sx={{ typography: 'paragraph' }}>
                      {tt('pages.onboarding.step-welcome.text')}
                    </Typography>
                  </SpacingBox>
                </CardContent>
              </Card>
            </SpacingBox>
          </Grid>
          <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
          <Grid container spacing={3} sx={{ marginTop: 0, marginLeft: 0 }}>
            <Grid container item xs={12} md={10} justifyContent="flex-end">
              <Button size="large" type="submit" variant="contained" onClick={handleNextStep}>
                {tt('actions.next')}
              </Button>
            </Grid>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
