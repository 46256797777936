import { Grid, Card, CardHeader, CardContent, Typography, Button } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { tt } from 'src/locales/i18n';
import { dispatch, useSelector } from '../../redux/store';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { onNextStep, onPrevStep } from 'src/redux/slices/onboarding';
import SpacingBox from 'src/components/spacingbox/SpacingBox';
import { SeoIllustration } from 'src/assets';
import useAuth from '../../hooks/useAuth';
import { EditoricalAPI } from 'src/utils/api';
import { actions as userActions } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

const STEPNO = 1;

type OnboardingStep1Props = {
  visible: boolean;
};

interface FormValuesProps {
  username: string;
  product: string;
  product_unused: string;
}

export default function OnboardingStepUsername({ visible }: OnboardingStep1Props) {
  // const { themeStretch } = useSettings();
  const { activeStep } = useSelector((state) => state.onboarding);
  const currentStep = activeStep === STEPNO;
  const { user } = useAuth();

  const defaultValues = useMemo(
    () => ({
      username: user ? user.displayName : '',
      product: '',
      product_unused: '',
    }),
    [user]
  );

  const OnboardingSchema = Yup.object().shape({
    username: Yup.string().required(tt('pages.onboarding.step-username.username-required')),
    product:
      user?.tenants?.length > 0
        ? Yup.string().optional()
        : Yup.string().required(tt('pages.onboarding.step-username.product-required')),
    product_unused: Yup.string().optional(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(OnboardingSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!user?.id) {
        throw new Error('User not logged in');
      }
      if (user?.displayName !== data.username) {
        await EditoricalAPI.updateUserName(user?.id, data.username);
        dispatch(userActions.updateUsername(data.username));
      }
      if (!(user?.tenants?.length > 0)) {
        const tenant = await EditoricalAPI.createTenant(user.id, data.product);
        dispatch(userActions.addTenant(tenant));
      }
      dispatch(onNextStep());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // load data here and fill it in
  }, [currentStep]);

  const handlePrevStep = () => {
    dispatch(onPrevStep());
  };

  return (
    <>
      {visible && (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            <Grid item xs={12} md={2} sx={{ paddingLeft: '0 !important' }}>
              <SeoIllustration
                sx={{
                  p: 3,
                  width: '100%',
                  margin: { xs: 'auto', md: 'inherit' },
                  display: { xs: 'none', md: 'block' },
                  padding: 0,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SpacingBox>
                <Card>
                  <CardHeader title={tt('pages.onboarding.step-username.username-title')} />
                  <CardContent>
                    {/* <Typography sx={{ typography: 'caption' }}>Card Holder</Typography>
                  <Typography sx={{ typography: 'subtitle1' }}> Card Holder</Typography>
                  <Typography sx={{ typography: 'subtitle2' }}> Card Holder</Typography> */}

                    <SpacingBox>
                      <Typography sx={{ typography: 'paragraph' }}>
                        {tt('pages.onboarding.step-username.username-text')}
                      </Typography>
                      <RHFTextField
                        name="username"
                        label={tt('pages.onboarding.step-username.username')}
                      />
                    </SpacingBox>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader title={tt('pages.onboarding.step-username.product-title')} />
                  <CardContent>
                    <SpacingBox>
                      <Typography sx={{ typography: 'paragraph' }}>
                        {tt('pages.onboarding.step-username.product-text')}
                      </Typography>
                      {user?.tenants?.length > 0 ? (
                        <RHFTextField
                          name="product_unused"
                          label={tt('pages.onboarding.step-username.product')}
                          disabled={true}
                          value={user?.tenants?.length > 0 ? user?.tenants[0].name : ''}
                        />
                      ) : (
                        <RHFTextField
                          name="product"
                          label={tt('pages.onboarding.step-username.product')}
                        />
                      )}
                      {/* TODO button für namen Generierung... */}
                    </SpacingBox>
                  </CardContent>
                </Card>
              </SpacingBox>
            </Grid>
            <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            <Grid container spacing={3} sx={{ marginTop: 0, marginLeft: 0 }}>
              <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
              <Grid item xs={6} md={4}>
                <Button size="large" type="button" variant="contained" onClick={handlePrevStep}>
                  {tt('actions.prev')}
                </Button>
              </Grid>
              <Grid container item xs={6} md={4} justifyContent="flex-end">
                <Button size="large" type="submit" variant="contained">
                  {tt('actions.next')}
                </Button>
              </Grid>
              <Grid item xs={12} md={2} display={{ xs: 'none', md: 'block' }} />
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
